import React, { useState, useEffect, useRef, memo } from 'react'
import { useNavigate } from "react-router-dom";
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Menu from './appbar';
import PersonaldataDialog from './personaldatadialog'
import api from '../helper/services';
import { Stack, Grid, Autocomplete, styled, Checkbox, Radio, FormControlLabel, Button, TextField, Box, Paper, Collapse, Alert, IconButton, Typography, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QRCode from 'qrcode'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import moment from 'moment';

import DisclaimerDialog from './disclamerDialog'
import { makeStyles } from "@material-ui/core/styles";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AdapterDateFnsBuddhist from '@tarzui/date-fns-be';
import { format } from 'date-fns';
import { th } from 'date-fns/locale';
const useStyles = makeStyles({
    paper: {

    }
});
const CustomPaper = (props) => {
    return <Paper elevation={8} sx={{ border: 3, borderColor: 'primary.main' }} {...props} />;
};

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <ExpandMoreIcon {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));


const Answerdiv = styled((props) => {
    const { item_style, ...other } = props;
    return <div  {...other}></div>;
})(({ theme, item_style }) => {
    for (var i of Object.keys(item_style)) {
        item_style[i] = Object.byString(theme, item_style[i]) || item_style[i];
    }
    return (item_style)
});

const Smdquestion = () => {

    let [selected, setSelected] = useState(null);
    let [selected2, setSelected2] = useState(null);
    const classes = useStyles();
    const [opendisclaimerdialog, setOpendisclaimerdialog] = React.useState({ open: true });
    const [opendialog, setOpendialog] = React.useState({ open: false });
    const [autoCompleteoption, setAutoCompleteoption] = useState([]);
    const [autoCompleteoption2, setAutoCompleteoption2] = useState([]);
    const pagelist = useRef([]);
    const currentpageindex = useRef(0);
    const age = useRef();
    const gender = useRef();
    const dob = useRef();
    const user_id = useRef('1234');
    const chat_room_id = useRef('1234');
    const hospital = useRef('9999');
    const self_exam = useRef(true);
    const dob_exist = useRef(false);
    const gender_exist = useRef(false);
    const haspersonalinfo = useRef(false);
    const answer = useRef([]);
    const [currentanswer, setCurrentAnswer] = useState({});
    answer.current = answer.current.length == 0 ? (window.sessionStorage.getItem("answer") ? JSON.parse(window.sessionStorage.getItem("answer")) : []) : answer.current;
    const { t, i18n } = useTranslation('translations');
    const [searchParams, setSearchParams] = useSearchParams();
    let topic = useRef({ topid_id: 0 });
    const [page, setPage] = useState({ note: "" });
    const navigate = useNavigate();
    const [transactionid, setTransactionid] = useState("");
    const [warningtext, setWarningtext] = useState("");
    const [warning, setWarning] = useState(false);
    const [wait, setWait] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [value, setValue] = React.useState([]);
    const [rerender, setRerender] = useState(false);
    let [dateAdapter, setdateAdapter] = useState((i18n.language.toLowerCase() == "th" || i18n.language.toLowerCase() == "th-th" ? "th" : "en"));
    const handleExpandClick = () => {

        setExpanded(!expanded);

    };

    useEffect(() => {
        if (selected2) {
            for (var item of document.getElementsByName("topiclist")) {
                item.checked = false;
            }
            document.getElementById("submitform").click()
        }
    }, [selected2]);

    useEffect(() => {
        setRerender(!rerender);
    }, [dateAdapter]);

    useEffect(() => {
        async function checkparam() {
            let gender, dob
            if (searchParams.has("user_id")) {
                const userid_param = searchParams.get("user_id");
                if (userid_param) {
                    user_id.current = userid_param;

                    searchParams.delete("user_id");
                }


            }
            if (searchParams.has("chat_room_id")) {
                const chat_room_id_param = searchParams.get("chat_room_id");
                if (chat_room_id_param) {
                    chat_room_id.current = chat_room_id_param;

                    searchParams.delete("chat_room_id");
                }


            }
            if (searchParams.has("hastel")) {
                const userinfo_param = searchParams.get("hastel");
                if (userinfo_param.toLowerCase() == "y") {
                    haspersonalinfo.current = true;
                }
            }
            if (searchParams.has("hasemail")) {
                const userinfo_param = searchParams.get("hasemail");
                if (userinfo_param.toLowerCase() == "y") {
                    haspersonalinfo.current = true;
                }
            }

            if (searchParams.has("self_exam")) {
                const self_exam_param = searchParams.get("self_exam");
                if (self_exam_param) {
                    self_exam.current = (self_exam_param.toUpperCase() == 'Y');

                    searchParams.delete("self_exam");
                }


            }

            if (searchParams.has("hospital")) {
                const hospital_param = searchParams.get("hospital");
                if (hospital_param) {
                    hospital.current = hospital_param;

                    searchParams.delete("hospital");
                }


            }
            if (searchParams.has("dob")) {
                const dobparam = searchParams.get("dob");
                if (dobparam) {
                    dob = dobparam;
                    dob_exist.current = true;
                    await api.getPageByName("dob").then(page =>
                        answer.current.push({ page: page, value: { dob: dob, age: window.calculateAge(dob) }, topic_id: topic.current.topid_id }));

                    searchParams.delete("dob");
                }


            }
            else {
                if (!pagelist.current.some(e => e === "dob") && !answer.current.some(e => e.page.name === "dob")) pagelist.current.push("dob");
            }
            if (searchParams.has("gender")) {
                const genderparam = searchParams.get("gender");
                if (genderparam) {
                    gender = genderparam;
                    gender_exist.current = true;
                    await api.getPageByName("gender").then(page => answer.current.push({ page: page, value: { gender: gender }, topic_id: topic.current.topid_id }));

                    searchParams.delete("gender");
                }


            }
            else {
                if (!pagelist.current.some(e => e === "gender") && !answer.current.some(e => e.page.name === "gender")) pagelist.current.push("gender");

            }

            window.sessionStorage.setItem("answer", JSON.stringify(answer.current));
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                // setSearchParams(searchParams); //getNextPage();
            } else {
                //setSearchParams(searchParams);
            }
            getNextPage();
        }
        checkparam();


    }, []);

    async function getPage(pageid) {
        if (!pageid) {
            var PageName = pagelist.current[currentpageindex.current];

            var page = await api.getPageByName(PageName, true);
            setPage(page);
            setWait(false);
        } else {

            var page = await api.getPageById(topic.current.topid_id, pageid, age.current, gender.current);
            setPage(page);
            setWait(false);
        }

    };
    useEffect(() => {
        if (Object.keys(currentanswer).length === 0) return;
        for (const item of Object.keys(currentanswer)) {
            var itemvalue = currentanswer[item];
            var itemnode = document.querySelector(`[name=${item}]`);
            if (!itemnode) continue;
            if (["radio", "checkbox"].includes(itemnode.type)) {

                var node = document.querySelector(`[name=${item}][value="other"]`);
                if (node && itemvalue !== "") {

                    if (currentanswer[item + "checked"] == 1) {
                        node.click();
                        node.checked = true;
                    }

                    var textnode = (document.querySelector(`textarea[name=${item}]`) ?? document.querySelector(`inpit[type='text'][name=${item}]`));
                    if (textnode) textnode.value = itemvalue;
                }

                node = document.querySelector(`[name=${item}][value="${itemvalue}"]`);
                if (node) {

                    node.click();
                    node.checked = true;
                }




            } else {
                document.querySelector(`[name=${item}]`).value = itemvalue;
            }
        }
    }, [currentanswer]);

    async function Changelanguage(lang) {
        if (lang.toLowerCase() == "th" || lang.toLowerCase() == "th-th") {
            setdateAdapter("th")
        } else
            setdateAdapter("en")

    };


    async function getPreviousPage() {
        if (answer.current.length > 0) {
            if (topic.current.topid_id == 0)
                currentpageindex.current--;
            var currentanswer = answer.current[answer.current.length - 1];
            topic.current.topid_id = currentanswer.topic_id;
            if (!currentanswer.page.items) return;
            else {
                answer.current.pop();

            }
            setPage(currentanswer.page);
            setWait(false);
            setCurrentAnswer(currentanswer.value);

        }
    };

    async function sendresult(result) {
        setWait(true);
        var jsonData = result || opendialog.result;
        var response = api.sendResult(jsonData);
        setWait(false);
        navigate("/result/" + jsonData.ticket_id);


    }
    async function sendpersonaldata(data) {
        if (wait) return;

        data.tel = data.tel.replace("+66", "0");
        var jsonData = opendialog.result;
        jsonData.save_profile = Object.assign({}, jsonData.save_profile, {
            dob: dob.current,
            gender: gender.current,
            email: data.email,
            tel: data.tel
        });

        sendresult(jsonData);

    }
    async function getNextPage(currentpage, currentanswer) {
        if (currentpage && currentpage.destinations) {
            var selecteddestination;

            for (const destination of currentpage.destinations) {
                var criteriaanswer = Object.assign({ skip: 0 }, currentanswer)
                var criteriafunction = [];
                criteriafunction.push("c");
                criteriafunction.push("age");
                criteriafunction.push("gender");
                var criteria = new Function(criteriafunction, `try{ return ` + (destination.criteria || 'true') + `; }catch(e){console.log(e);return false}`);
                var param = []
                if (currentpage.topic?.topic_id) {
                    var anwerlist = answer.current.filter(ans => ans.topic_id == currentpage.topic.topic_id);
                    for (var ans of anwerlist) {


                        criteriaanswer = Object.assign(criteriaanswer, ans.value);
                    }

                }
                param.push(criteriaanswer);
                param.push(age.current);
                param.push(gender.current);


                var result = criteria(...param);
                if (result) {
                    selecteddestination = destination;
                    break;
                }
            }

        }
        if (selecteddestination) {
            if (selecteddestination.destination_topic_id) topic.current.topid_id = selecteddestination.destination_topic_id;
            if (selecteddestination.destination_type == "page") {
                var page = await api.getPageById(topic.current.topid_id, selecteddestination.destination_id, age.current, gender.current);
                if (page) {
                    setPage(page);
                    setWait(false);
                }
            } else if (selecteddestination.destination_type == "logic") {
                setWait(true);
                var result = await api.saveResult(selecteddestination.destination_logic, user_id.current, self_exam.current, answer.current, age.current, gender.current, topic.current.topid_id, hospital.current, chat_room_id.current);
                setTransactionid(result.transaction_id)
                var jsonData = {
                    "type": "finish_smd",
                    "ticket_id": result.transaction_id,
                    "chat_room_id": chat_room_id.current,
                    "qr_code_base64": "",
                    "url": `${window.location.protocol}//${window.location.host}${(window.location.pathname + "result/").replace("//", "/")}` + result.transaction_id,
                    "save_profile": {
                        "user_id": user_id.current,

                    }

                };

                QRCode.toDataURL(jsonData.url.replace("result", "view"), {
                    width: 345,
                    margin: 2,
                    color: {
                        // dark: '#335383FF',
                        // light: '#EEEEEEFF'
                    }
                }, async (err, url) => {
                    if (err) return console.error(err)
                    //console.log(url)
                    jsonData.qr_code_base64 = url;
                    //window?.ReactNativeWebView?.postMessage(JSON.stringify(jsonData));
                    if (!haspersonalinfo.current) {
                        setWait(false);
                        setOpendialog({ open: true, result: jsonData });

                    }
                    else {
                        api.sendResult(jsonData);
                        setWait(false);
                        navigate("/result/" + result.transaction_id);

                    }

                })



            }
        }
        if (topic.current.topid_id == 0) {
            if (pagelist.current.length > currentpageindex.current)
                getPage();
            else {

                window.sessionStorage.removeItem("answer");
                if (answer.current.length == 0) return;
                listTopic();
            }
        }

    };
    async function listTopic() {
        age.current = answer.current.find(e => e.page.name === "dob").value.age;
        dob.current = answer.current.find(e => e.page.name === "dob").value.dob;
        gender.current = answer.current.find(e => e.page.name === "gender").value.gender;
        var page = await api.getTopicList(age.current, gender.current);
        if (page) {
            setAutoCompleteoption2(page.items.map(e => ({ "label": e.note, ...(e.item_property ? JSON.parse(e.item_property) : { value: "1" }) })))
            setAutoCompleteoption(
                page.items.filter(e => e.optional === "A").map(e => ({ "label": e.note, ...(e.item_property ? JSON.parse(e.item_property) : { value: "1" }) })));
            setPage(page);
            setWait(false);
        }

    };


    function createBinding(item, runonce) {
        var fn;
        if (item.bindingtarget)
            fn = new Function(`try{document.getElementsByName('${item.bindingtarget}')[0].value =${item.bindingscript}}catch(e){console.log(e);}`)
        else
            fn = new Function(`try{${item.bindingscript}}catch(e){console.log(e);}`)

        return fn;
    }

    const handleChange = (newValue, inputid) => {
        if (newValue){
            value[inputid] =newValue
            setValue(value);
            setRerender(!rerender);
        }
    };

    function handleSubmit(e, page) {
        var error = "";
        try {
            e.preventDefault();

            if (wait) return;
            setWait(true);

            if (page.bindingscript) {
                const items = {};
                for (const pair of (new FormData(e.target)).entries()) {
                    if (pair[0] == "dob") {
                        pair[1] = value["dob11"] ? moment(value["dob11"]).format('YYYY-MM-DD') : (dateAdapter == "th" ? moment(pair[1], 'DD-MM-YYYY').add(-543, 'year') : moment(pair[1], 'DD-MM-YYYY')).format('YYYY-MM-DD');

                    }
                    items[pair[0]] = pair[1];
                }
                var criteriafunction = Object.keys(items);
                var binding = new Function(criteriafunction, `${page.bindingtarget ? `try{document.getElementsByName('${page.bindingtarget}')[0].value =` : ""}${page.bindingscript}}catch(e){console.log(e);}`);
                binding(...Object.values(items));

            }

            let data = {};
            if (page.name == "topiclist" && selected2?.value) {
                data.topiclist = selected2.value;
            }
            for (const pair of (new FormData(e.target)).entries()) {
                if (pair[0] == "dob") {
                    pair[1] = value["dob11"] ? moment(value["dob11"]).format('YYYY-MM-DD') : (dateAdapter == "th" ? moment(pair[1], 'DD-MM-YYYY').add(-543, 'year') : moment(pair[1], 'DD-MM-YYYY')).format('YYYY-MM-DD');

                }
                if (page.name != "topiclist") {
                    if (!data[pair[0]] || (data[pair[0]] && data[pair[0]] == "other")) {
                        if(pair[1]){
                            data[pair[0]] = pair[1];
                        if (data[pair[0]] && data[pair[0]] == "other") {
                            data[pair[0] + "checked"] = 1;
                            data[pair[0]]="";
                        }
                       
                    }
                    }
                }
                else {

                    data[pair[0]] = pair[1];
                }

            }

            if (data.topiclist == "other") {
                if (null !== selected)
                    data.topiclist = selected.value;
                else {
                    data = {};
                }
            }

            if (page.name === "dob") {
                age.current = data.age;
                if (isNaN(age.current) || age.current < 0) {
                    data = {};
                    error = "Invalid";

                } else if (age.current > 150 || age.current < 10) {
                    data = {};
                    error = "Invaliddate";
                }
            }


            console.logitem(data);
            if (Object.keys(data).length !== 0) {
                setWarningtext("");
                setWarning(false);
                answer.current.push({ page: page, value: data, topic_id: topic.current.topid_id });
                if (pagelist.current.length > currentpageindex.current) currentpageindex.current++;
                getNextPage(page, data);
            } else {
                setWarningtext(error);
                setWarning(true);

                setWait(false);
            }
        } catch (e) {
            console.log(e);
            setWait(false);
        }
    }

    function OtherInput(props) {
        var e = props.data;
        const [hasother, setHasother] = React.useState(false);
        const [inputValue, setinputValue] = React.useState("");
        return <Stack><Stack direction="row" alignItems="flex-start">

            <label htmlFor={(e.page_name || page.name || 'item' + e.page_id) + e.id}><input
                type={props.type}
                name={e.name || e.page_id}
                {...(e.item_property ? { ...JSON.parse(e.item_property), style: undefined } : { value: "1" })}
                id={(e.page_name || page.name || 'item' + e.page_id) + e.id}
                onClick={e.bindingscript ? createBinding(e) : undefined}
                onLoad={e.bindingscript ? createBinding(e) : undefined}
                defaultChecked={e.defaultvalue ? true : undefined}

                value="other"
                onChange={(e) => {
                    setHasother(e.target.checked)

                }}
            />
                {t(e.note)}</label>
        </Stack>
            <TextField sx={{ pl: 1 }}
                size="small"
                multiline
                name={e.name || e.page_id}
                type={e.item_type}
                onChange={(event) => {
                    var other = document.getElementById((e.page_name || page.name || 'item' + e.page_id) + e.id);
                    if (!other?.checked) other.click();
                    (event.target.value ? setHasother(true) : setHasother(false));
                    setinputValue(event.target.value);
                }}

                {...(e.item_property ? JSON.parse(e.item_property) : {})}
                id={(e.page_name || page.name || 'item' + e.page_id) + e.id}
                defaultValue={e.defaultvalue ? e.defaultvalue : undefined} />

        </Stack>;
    }

    function mapitem(e) {
        return (
            <React.Fragment key={(e.page_name || page.name || 'item' + e.page_id) + e.id}>
                {(() => {
                    switch (e.item_type) {
                        case "radio":
                        case "checkbox":
                            return (<Answerdiv item_style={(e.item_style ? JSON.parse(e.item_style) : {})}><label htmlFor={(e.page_name || page.name || 'item' + e.page_id) + e.id}><input
                                type={e.item_type}
                                name={e.name || e.page_id}
                                {...(e.item_property ? JSON.parse(e.item_property) : { value: "1" })}
                                id={(e.page_name || page.name || 'item' + e.page_id) + e.id}
                                onClick={e.bindingscript ? createBinding(e, true) : undefined}
                                defaultChecked={e.defaultvalue ? true : undefined}
                            />
                                {t(e.note)}</label></Answerdiv>)
                        case "hidden":
                            return (<input
                                type={e.item_type}
                                name={e.name || e.page_id}
                                {...(e.item_property ? JSON.parse(e.item_property) : {})}
                                id={(e.page_name || page.name || 'item' + e.page_id) + e.id}
                                value={e.defaultvalue ? e.defaultvalue : undefined} />)
                        case "date":
                           
                            return (<Answerdiv item_style={(e.item_style ? JSON.parse(e.item_style) : {})}><DesktopDatePicker
                                label={t(e.note)}
                                inputFormat="dd/MM/yyyy"
                                minDate={new Date(((new Date().getFullYear()) - 150).toString() + '-01-01')}
                                maxDate={new Date()}
                                value={value[(e.page_name || page.name || 'item' + e.page_id) + e.id] ? value[(e.page_name || page.name || 'item' + e.page_id) + e.id]  : e.defaultvalue}
                              
                                onChange={(newValue) => handleChange(newValue, (e.page_name || page.name || 'item' + e.page_id) + e.id)}
                                renderInput={(params) => <TextField {...params}
                                    size="small"
                                    sx={{width :"100%"}}
                                    name={e.name || e.page_id}
                                    {...(e.item_property ? JSON.parse(e.item_property) : {})}
                                    id={(e.page_name || page.name || 'item' + e.page_id) + e.id}
                                />}
                            /></Answerdiv>)

                        case "otherradio": return (<Answerdiv item_style={(e.item_style ? JSON.parse(e.item_style) : {})}><OtherInput type="radio" data={e} /></Answerdiv>)
                        case "othercheckbox": return (<Answerdiv item_style={(e.item_style ? JSON.parse(e.item_style) : {})}><OtherInput type="checkbox" data={e} /></Answerdiv>)
                        default:
                            return (<Answerdiv item_style={(e.item_style ? JSON.parse(e.item_style) : {})}><TextField
                                size="small"
                                sx={{width :"100%"}}
                                type={e.item_type}
                                label={t(e.note)}
                                name={e.name || e.page_id}
                                {...(e.item_property ? JSON.parse(e.item_property) : {})}
                                id={(e.page_name || page.name || 'item' + e.page_id) + e.id}
                                defaultValue={e.defaultvalue ? e.defaultvalue : undefined} /></Answerdiv>)
                        // code block
                    }
                })()}


            </React.Fragment>)
    }
    return (
        <div>
            <Menu self_exam={self_exam.current} age={age.current} dob={dob.current} gender={gender.current} allowPrevious={answer.current.length > 0 && answer.current[answer.current.length - 1].page.items} PreviousCallback={getPreviousPage} LanguageChangeCallback={Changelanguage} />

            <Box ml={2} mt={2} mr={2} >

                <Collapse in={warning}>
                    <Alert severity="error"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {

                                    setWarning(false);
                                    setWarningtext("");
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        {t(warningtext === "" ? "Required field" : warningtext)}
                    </Alert>
                </Collapse>
                {hospital.current && <Typography sx={{ fontSize: 16 }} align="right" color="text.secondary">{t("hospital_code_" + hospital.current)}</Typography>}
                <Grid sx={{ flexDirection: 'column', justifyContent: 'center', display: "grid", width: 1 }}
                    onSubmit={(e) => handleSubmit(e, page)}
                    component="form"
                    id="smdform"
                >
                    <Paper sx={{ p: 2, maxWidth: "85vw", width: "1000px", minHeight: "60vh", mb: 2 }} >
                        <Grid style={{ display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'space-between', alignItems: "flex-end" }} >
                            <Stack
                                sx={{ flexGrow: 1, width: 1,mb:2 }}>
                                {page.items?.length == 0 && <Alert severity="error"
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"

                                        >

                                        </IconButton>
                                    }
                                    sx={{ mb: 2 }}
                                >
                                    {t("Invaliddate")}
                                </Alert>}
                                {page.items?.length > 0 && <Typography variant="h5" component="div" align='center' mb={2}>{t(page.note)}</Typography>}
                                <Divider variant="middle" />
                                {page.topic?.note && <Typography sx={{ fontSize: 14 }} mt={1} align="right" color="text.secondary">{t("Topic") + ": " + t(page.topic.note)}</Typography>}

                                <Stack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"

                                    autoComplete="off"


                                >
                                    <Stack
                                        spacing={2}
                                        mt={2}
                                        sx={{ maxWidth: "85vw" }}
                                    >
                                        {page.name == "topiclist" && page.items.length > 0 && <Autocomplete
                                            value={selected2}
                                            PaperComponent={CustomPaper}
                                            onChange={(_, v) => { setSelected2(v); }}
                                            disablePortal
                                            classes={{ paper: classes.paper }}
                                            id="searchautocomplete"

                                            options={autoCompleteoption2}
                                            sx={{ width: 300 }}

                                            getOptionLabel={(option) => t(option.label)}

                                            renderInput={(params) => <TextField {...params} error={warning} label={t("search")} />}
                                        />}
                                        {dateAdapter == "en" && <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={th} >

                                            {page.items?.length
                                                ? (
                                                    //topic.items.map(e => <Trans i18nKey={e.item_description} key={e.id} >{ReactHtmlParser(createMarkup(e))}</Trans>)
                                                    //topic.items.map(e => <div key={e.id} dangerouslySetInnerHTML={createMarkup(e)}></div>)

                                                    page.items.filter(e => e.optional !== "Y" && e.optional !== "A").map(mapitem)

                                                ) : ''
                                            }
                                        </LocalizationProvider>
                                        }
                                        {dateAdapter == "th" && <LocalizationProvider dateAdapter={AdapterDateFnsBuddhist} adapterLocale={th} >
                                            {page.items?.length
                                                ? (
                                                    //topic.items.map(e => <Trans i18nKey={e.item_description} key={e.id} >{ReactHtmlParser(createMarkup(e))}</Trans>)
                                                    //topic.items.map(e => <div key={e.id} dangerouslySetInnerHTML={createMarkup(e)}></div>)

                                                    page.items.filter(e => e.optional !== "Y" && e.optional !== "A").map(mapitem)

                                                ) : ''
                                            }
                                        </LocalizationProvider>
                                        }


                                        {page.items?.filter(e => e.optional === "Y").length > 0 && <>
                                            <Button variant="outlined" onClick={handleExpandClick} startIcon={<ExpandMore
                                                expand={expanded}
                                                size="small"
                                                aria-expanded={expanded}
                                                aria-label="show more"
                                            >

                                            </ExpandMore>} size="small" sx={{
                                                width: '25ch',
                                            }}>
                                                {t("optional answer")}
                                            </Button>
                                            <Collapse in={expanded} timeout="auto">
                                                <Stack spacing={2}>
                                                    {page.items?.length
                                                        ? (
                                                            //topic.items.map(e => <Trans i18nKey={e.item_description} key={e.id} >{ReactHtmlParser(createMarkup(e))}</Trans>)
                                                            //topic.items.map(e => <div key={e.id} dangerouslySetInnerHTML={createMarkup(e)}></div>)

                                                            page.items.filter(e => e.optional === "Y").map(mapitem)

                                                        ) : ''
                                                    }
                                                </Stack>
                                            </Collapse></>}

                                        {page.items?.filter(e => e.optional === "A").length > 0 && <>
                                            <label htmlFor={page.name + "other"}><input
                                                type="radio"
                                                name={page.name}
                                                value="other"
                                                id={page.name + "other"}
                                            />
                                                {t("Other: ")}</label>
                                            <Autocomplete
                                                value={selected}
                                                PaperComponent={CustomPaper}
                                                onChange={(_, v) => setSelected(v)}
                                                disablePortal
                                                classes={{ paper: classes.paper }}
                                                id="otherautocomplete"

                                                options={autoCompleteoption}
                                                sx={{ width: 350 }}

                                                getOptionLabel={(option) => t(option.label)}

                                                renderInput={(params) => <TextField {...params} error={warning} label={t("other")} onFocus={() => document.querySelector("input[value='other']").checked = true} />}
                                            />
                                        </>}
                                    </Stack>
                                </Stack>
                            </Stack>


                            {page.items?.length > 0 && <Button variant="contained" size="small" color='secondary'
                                form="smdform"
                                id="submitform"

                                sx={{
                                    width: '25ch',
                                }} type="submit" value="Submit">{t("Next")}</Button>}
                        </Grid>
                    </Paper>
                </Grid>
            </Box>

            <PersonaldataDialog open={opendialog.open} closecallback={sendresult} savecallback={sendpersonaldata} />
            <DisclaimerDialog open={opendisclaimerdialog.open} LanguageChangeCallback={Changelanguage} />
        </div>
    );
};


export default Smdquestion;