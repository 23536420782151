import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import Menu from '@mui/material/Menu'
import MuiMenuItem from '@mui/material/MenuItem'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import {
    usePopupState,
    bindTrigger,
    bindMenu,
} from 'material-ui-popup-state/hooks'
import { withStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    appBar: {
        paddingTop: `env(safe-area-inset-top)`,
        paddingLeft: `env(safe-area-inset-left)`,
        paddingRight: `env(safe-area-inset-right)`
    },
}));
const MenuItem = withStyles({
    root: {
        justifyContent: "flex-end"
    }
})(MuiMenuItem);

const MenuPopupState = (props) => {
    const { t, i18n } = useTranslation('translations');
    const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' })
    const classes = useStyles();

    function changeLanguageHandler(lang) {
        i18n.changeLanguage(lang)
        popupState.close();
        props.LanguageChangeCallback?.apply(this, [lang]);
    }
    function getPreviousPage() {
        props.PreviousCallback.apply();
    }


    return (

        <Box sx={{ flexGrow: 1,mb:8}}>
            <AppBar className={classes.appBar} position="fixed">
                <Toolbar variant="dense">
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item>
                            {props.allowPrevious && <IconButton onClick={getPreviousPage}
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            }
                        </Grid>


                        <Grid item>
                            <Stack direction="row" alignItems="center">
                                <Button color="inherit"   {...bindTrigger(popupState)}>
                                    <Typography>{i18n.language}</Typography>
                                </Button>

                                <Menu {...bindMenu(popupState)}>
                                    <MenuItem onClick={() => changeLanguageHandler("en")}>EN</MenuItem>
                                    <MenuItem onClick={() => changeLanguageHandler("th")}>TH</MenuItem>
                                </Menu>
                                {props.self_exam &&<PersonIcon /> }
                                {!props.self_exam && props.self_exam !== undefined  &&<PeopleIcon /> }
                                {props.dob && <Chip color="primary" icon={props.gender && (props.gender == "M" ? <MaleIcon /> : <FemaleIcon />)} label={t("Agelabel", { age: (props.age == 0 ? window.calculateAgeMonth(props.dob) : props.age) }) + ' ' + t(props.age == 0 ? "M" : "Y")} />}

                                <IconButton sx={{ml:1}} onClick={() => {
                                    window?.ReactNativeWebView?.postMessage(JSON.stringify({ "type": "cancel_smd" }))
                                }}
                                    size="large"
                                    edge="start"
                                    aria-label="menu"
                                    color="inherit"

                                >
                                    <CloseIcon />
                                </IconButton>
                            </Stack>
                        </Grid>

                    </Grid>

                </Toolbar>
            </AppBar>
        </Box >

    )
}

export default MenuPopupState