export const themeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#00646B',
      contrastText: '#eee',
    },
    secondary: {
      main: '#AF826A',
      contrastText: '#eee',
    },
    background: {
      default: '#eeeeee',
      paper: '#ffffff',
    },
    divider: '#616161',
    text: {
      primary: '#00646B',
    },
  },
  overrides: {
    MuiAppBar: {
      colorInherit: {
        backgroundColor: '#689f38',

      },
    },
  },

};